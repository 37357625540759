import { useState, useEffect } from "react";

import Intro from "./pages/Intro/Intro";
import Roulette from "./pages/Roulette/Roulette";
import ResultPage from "./pages/ResultPage/ResultPage";
import Header from "./components/Header/Header";
import ResultNote from "./components/ResultNote/ResultNote";
import useWindowDimensions from "./hooks/useWindowDimensions";

function App() {
  const [pageNum, setPageNum] = useState(0);
  const [containerHeight, setContainerHeight] = useState(800);
  const [containerStyles, setContainerStyles] = useState({ height: 0 });
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (pageNum < 2) setContainerStyles({ height: "100%" });
    else setContainerStyles({ height: `${containerHeight}px` });
  }, [containerHeight, pageNum, width]);

  const goToNextPage = () => {
    setPageNum(pageNum + 1);
  };

  const goToPage = (page) => {
    setPageNum(page);
  };

  return (
    <>
      <div className="container" style={containerStyles}>
        <Header pageNum={pageNum} />
        {pageNum === 0 && <Intro goToNextPage={goToNextPage} />}
        {pageNum === 1 && <Roulette goToPage={goToPage} />}
        {pageNum > 1 && pageNum < 10 && (
          <ResultPage
            goToPage={goToPage}
            pageNum={pageNum}
            setContainerHeight={setContainerHeight}
          />
        )}
      </div>
      <ResultNote />
    </>
  );
}

export default App;
