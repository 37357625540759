import { useRef, useState, useLayoutEffect } from 'react'

import Button from "../../components/Button/Button";
import useImage from "../../hooks/useImage";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import data from "../../constants/data"
import styles from './ResultPage.module.scss'

const ResultPage = ({ goToPage, pageNum, setContainerHeight }) => {

    const { width } = useWindowDimensions();
    const [loaded, setLoaded] = useState(false);
    const ref = useRef(null);
    const height = ref?.current?.clientHeight + 300;
    useLayoutEffect(() => {
        setContainerHeight(height);
    }, [loaded])

    const isMobile = width <= 920 ? '_mob' : '';
    const imagePath = `pop_${pageNum - 1}${isMobile}.png`
    const { image } = useImage(imagePath);

    const dataPage = data[pageNum - 2];

    const onClickHandler = () => goToPage(1);

    return (
        <div className={styles.result_page}>
            <div className={styles.result_content}>
                <img style={{ height: `calc(${height})px` }} src={image} alt="" className={styles.result_img} ref={ref} onLoad={() => setLoaded(true)} />
                {dataPage.note && <p className={styles.result_note} dangerouslySetInnerHTML={{ __html: dataPage.note }} />}
                {dataPage.refs && <p className={styles.result_refs} dangerouslySetInnerHTML={{ __html: dataPage.refs }} />}
            </div>
            <div className={styles.result_info} id={pageNum}>
                <div className={styles.result_card}>
                    <p className={styles.result_title}>{dataPage.title}</p>
                    <p className={styles.result_text} dangerouslySetInnerHTML={{ __html: dataPage.text }} />
                </div>
                <Button text='Ещё одно предсказание' reload onClick={onClickHandler} />
            </div>
        </div>
    )
}

export default ResultPage