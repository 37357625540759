import reloadIcon from '../../assets/icons/reload.png'
import styles from './Button.module.scss';

const Button = ({text, reload = false, onClick}) => {
    return (
        <button className={styles.btn} onClick={onClick}>
            {reload && <img src={reloadIcon} alt='' className={styles.reload_icon}/>}
            <p className={styles.btn_text}>
                {text}
            </p>
        </button>
    )
}

export default Button;