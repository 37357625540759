const data = [
    {
        title: "Вы точно что-то планируете… Запаситесь терпением, держите от меня немного поддержки и все получится!",
        text: "(Витажиналь<sup>®</sup> Инозит - комплекс с инозитолом для женского и мужского здоровья: подготовка к беременности, комплексная поддержка обмена веществ и коррекция эстетических проблем*)",
        note: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВОМ",
        refs: "*у женщин с гиперандрогенией",
    },
    {
        title: "Ух, ну и вечеринку Вы затеяли... Вызывайте клининг, восстановить прежний порядок в доме мы не поможем! К нам обращаются с другими вопросами.",
        text: "(Лактожиналь<sup>®</sup>, Дуожиналь<sup>®</sup> - лактобактерии для восстановления и поддержания нормальной микрофлоры)",
        note: "Дуожиналь® БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВОМ",
    },
    {
        title: "Ого! Это Вы сегодня стали на год старше? Вы точно остановили время и неподвластны возрасту, а ведь это моя работа!",
        text: "(Фамвиталь<sup>®</sup> Смарт - антивозрастной комплекс антиоксидантов, витаминов и микроэлементов для поддержания красоты волос, ногтей и кожи*)",
        note: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВОМ",
        refs: "*Jacquet A, Coolen V, Vandermander J. Effect of dietary supplementation with INVERSION Femme on slimming, hair loss, and skin and nail parameters in women. Adv Ther. 2007 Sep-Oct;24(5):1154-71."
    },
    {
        title: "Вы обладаете непревзойденными лидерскими качествами, которые вдохновляют окружающих и помогают достигать целей с максимальной эффективностью.",
        text: "(Эстрожель<sup>®</sup> – глобальный лидер №1 среди всех монопрепаратов эстрадиола*)",
        refs: "*Source: Based on internal analysis by Besins Healthcare Monaco S.A.M. using data from the following source: IQVIA MIDAS® for the time period MAT Q2 2023; Geography: 72 countries; Measures: Standard Units and Counting Units; Molecule List: ESTRADIOL reflecting estimates of real-world activity. Copyright IQVIA. All rights reserved."
    },
    {
        title: "Кажется, Вы вынашиваете идею… Смело воплощайте ее, а я поддержу. ",
        text: "(Утрожестан<sup>®</sup> - микронизированный прогестерон, идентичный натуральному прогестерону женского организма, для поддержания репродуктивного здоровья женщины и рождения долгожданного малыша)",
    },
    {
        title: "Вы – настоящая сила, не имеющая преград в достижении цели! Поверьте, я в этом разбираюсь.",
        text: "(Андрогель<sup>®</sup> - трансдермальный гель тестостерона, первая линия терапии тестостероном у мужчин с гипогонадизмом)",
    },
    {
        title: "Вы выглядите великолепно! Откуда в Вас такая тяга к прекрасному? Хотя мне не трудно Вас понять: моя миссия не менее прекрасна!",
        text: "(Прожестожель<sup>®</sup> – забота о здоровье молочной железы)",
    },
    {
        title: "Вы умеете разобраться в том, что в жизни по-настоящему важно и правильно расставляете приоритеты. Такой подход наверняка принесет успех в Вашем новом году.",
        text: "(Витажиналь<sup>®</sup> Мама – 5 важных элементов для новой жизни и ничего лишнего. Комплекс для поддержки здоровья мамы и малыша, начиная с этапа прегравидарной подготовки до окончания лактации)",
        note: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВОМ",
    },
];

export default data;