import Button from "../../components/Button/Button"
import peopleImg from '../../assets/img/people.png'
import giftImg from '../../assets/img/gift.png'

import styles from "./Intro.module.scss"

const Intro = ({ goToNextPage }) => {

    // useEffect(() => {
    //     if (width > 600)
    //         setContainerHeight(ref.current.clientHeight + 150);
    // }, [containerHeight, setContainerHeight, width])

    return (
        <div className={styles.intro}>
            <div className={styles.intro__left}>
                <h2 className={styles.intro__title}>
                    От лица компании Безен Хелскеа мы поздравляем Вас с Днем Рождения!
                </h2>
                <p className={styles.intro__text}>
                    Желаем Вам исполнения всех желаний, достижения карьерных высот и безграничного счастья! Конечно, для этого необходимы потрясающие личные качества (да-да, у Вас как раз такие!) и благосклонность фортуны!
                </p>
                <p className={styles.intro__text}>
                    Проверьте свою сейчас и получите персональный комплимент в Ваш День.
                </p>
                <Button text="Получить предсказание" onClick={goToNextPage}></Button>
            </div>
            <div className={styles.intro__right}>
                <img src={giftImg} alt="" className={styles.gift_img} />
                <img src={peopleImg} alt="" className={styles.people_img} />
            </div>
        </div>
    )
}

export default Intro;