import styles from './ResultNote.module.scss';

const ResultNote = () => {
    return (
    <div className={styles.result_note}>
        <div className={styles.result_note_text}>
            ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ
        </div>
    </div>
    )
}

export default ResultNote;