import styled, { keyframes } from "styled-components";

import getRandomInt from '../../utils/getRandomInt'

import styles from './Roulette.module.scss'
import rouletteImg from '../../assets/img/roulette.png'
import triangleImg from '../../assets/img/triangle.png'
import flashImg from '../../assets/img/flash.png'
import { useEffect } from "react";

const spinTheWheel = (previousPages) => {
    if (previousPages.length === 8) previousPages = [];
    const degrees = getRandomInt(3, 5) * 360 + getRandomInt(1, 360);
    const nextPage = Math.ceil(degrees % 360 / 45) + 1;
    if (previousPages.includes(nextPage)) return spinTheWheel(previousPages)
    else {
        localStorage.setItem('previousPages', JSON.stringify([...previousPages, nextPage]))
        return [degrees, nextPage]
    }
}

const Roulette = ({ goToPage }) => {
    const previousPages = JSON.parse(localStorage.getItem('previousPages')) || [];

    const [degrees, nextPage] = spinTheWheel(previousPages)

    const spin = keyframes`
    0% { transform: rotate(0); }
    100% { transform: rotate(${degrees + 20}deg); }
    `;
    const RouletteSpin = styled.img`
    animation: ${spin} 5s ease-in-out forwards;
    width: 100%;
    `

    useEffect(() => {
        const timeout = setTimeout(() => {
            goToPage(nextPage);
        }, 7000);
        return () => {
            clearTimeout(timeout);
        }
    })

    return (
        <div className={styles.roulette}>
            <div className={styles.roulette_img}>
                <RouletteSpin src={rouletteImg} />
                <img className={styles.flash_img} src={flashImg} alt='' />
                <img className={styles.triangle_img} src={triangleImg} alt='' />
            </div>
        </div>
    )
}
export default Roulette
