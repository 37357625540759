import cx from 'classnames';

import logoImg from '../../assets/img/logo.png'
import styles from './Header.module.scss';

const Header = ({ pageNum }) => {
    return (
        <div className={cx(styles.header, pageNum > 2 ? styles.header_result : '')}>
            <img src={logoImg} alt="" className={styles.logo} />
        </div>
    )
}

export default Header;